.footer {
    box-shadow: 0 -5px 5px -5px #333;
    background: black;
    position: absolute;
    width: 100%;
    padding-top: rem-calc(40px);
    padding-top: rem-calc(40px);
    bottom:0;
    left: 0;
    height: rem-calc(462px);

    &__ul {
        list-style-type: none;
    }

    &__list-item__header, &__list-item__header:active, &__list-item__header:focus, &__list-item__header:visited {
        text-decoration: none;
        color: $primary;
    }
    &__list-item__text {
        color: #ffffff;
    }

    .footer__a, .footer__p {

    }

    .footer__a:first-of-type, .footer__p:first-of-type {

    }

    .footer__li {
        margin-top: rem-calc(0px);
        .footer__list-item__header {
            font-weight: 500;
            line-height: rem-calc(16px);
        }
        @media #{$large-up} {
            .footer__list-item__header {}
        }
        .footer__list-item__text {
            font-size: rem-calc(11px);
            line-height: rem-calc(20px);
        }
        @media #{$large-up} {
            .footer__list-item__text {
                font-size: rem-calc(13px);
                line-height: rem-calc(24px);
            }
        }

        p {
            margin-bottom: rem-calc(5px);
        }

    }

    .footer__li:first-of-type, {
        margin-top: rem-calc(0px);

        p {
            margin-bottom: rem-calc(20px);
            // font-size: rem-calc(14px);
            line-height: rem-calc(16px);
        }

    }

    .footer__li:nth-of-type(4) {
        p {
            margin-top: rem-calc(25px);
        }
    }
    .footer__a:first-of-type {
        margin-top: rem-calc(-10px);
    }

    .footer__a:hover{
        color: $primary;
        transition: all 0.2s ease-in-out;
        font-weight:bold;
    }

    .footer__copyright {
        color: $primary;
        font-size: rem-calc(11px);
        line-height: rem-calc(30);
    }

    @media #{$large-up} {
        .footer__copyright {
            font-size: rem-calc(12px);
        }
    }

    hr {
        border: 1px solid rgba(255, 255, 255, 0.16);
    }
}

@media #{$medium-only} {
    .footer {
        height: rem-calc(352px);
    }
}
@media #{$large-up} {
    .footer {
        height: rem-calc(352px);
    }
}
