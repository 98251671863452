.rulePrizeImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.rules__text p, .index__text p {
    font-size: rem-calc(16px);
    line-height: rem-calc(26px);
}

@media #{$medium-up} {
    .rulePrizeImage {

    }
    .rules__text p, .index__text p{
        font-size: rem-calc(18px);
    }
}
