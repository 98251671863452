.apl__aa__table--start {
    padding-top:3%;
    //TABLE HEADER
    &__header {
        height: rem-calc(40px);
        background: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;

        &__column:first-of-type {
            vertical-align: middle;
            display: inline-block;
            width:80%;
            text-align: left;
        }



        &__column {

            vertical-align: middle;
            text-align: center;
            display: inline-block;
            width:20%;
        }

        @media #{$medium-up}{
            &__column:first-of-type {
                width:80%;
            }
            &__column {
                width:20%;
            }
        }
        h1 {
            vertical-align: middle;
            display: inline-block;
            font-size: rem-calc(14px);
            margin: 0;
        }
        @media #{$medium-only} {
            h1 {
                font-size: rem-calc(16px);
            }
        }

        @media #{$large-up} {
            h1 {
                font-size: rem-calc(18px);
            }
        }
    }




    //TABLE BODY
    &__body {
        padding-top: 2%;
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        &__column:first-of-type {
            vertical-align: middle;
            display: inline-block;
            width:80%;
            text-align: left;
        }



        &__column {

            vertical-align: middle;
            text-align: center;
            display: inline-block;
            width:20%;
        }


        @media #{$medium-up}{
            &__column:first-of-type {
                width:80%;
            }



            &__column {
                width:20%;
            }

        }
        span, p {

            display: inline-block;
            font-size: rem-calc(12px);
        }
        @media #{$medium-only}{
            span, p {
                font-size: rem-calc(14px);
            }
        }
        @media #{$large-up} {
            span, p {
                font-size: rem-calc(16px);
            }
        }
        &__position {
            // margin-left: rem-calc(10px);
            margin-right: rem-calc(2px);
        }
        @media #{$large-up} {
            &__position {
                // margin-left: rem-calc(10px);
                margin-right: rem-calc(10px);
            }

        }

    }
}
