body {
    height: 100%;
    background: #FAFAFA;
}
header {
    padding-top: rem-calc(50px);
}
@media #{$medium-up} {
    header {
        padding-top: rem-calc(80px);
    }
}

.site__header {
    font-size: rem-calc(50px);

}

.site-wrap{
    overflow:hidden;
    // width:100%;
    min-height:100%;
    position: relative;
}

#content {
    padding-bottom: rem-calc(420px);
    margin-bottom: rem-calc(40px);
}

@media #{$medium-only} {
    #content {
        padding-bottom: rem-calc(-20px);
        // margin-bottom: rem-calc(60px);
    }
}
@media #{$large-up} {

    #content {
            // margin-bottom: -145px;
        // margin-top: rem-calc(87px);
        // margin-bottom: rem-calc(80px);
        // padding-bottom: rem-calc(376px);
        padding-bottom: rem-calc(-20px) !important;
    }
}

.table-icon {
    height: rem-calc(15px);
    width: auto;
}

.button, .btn {
    width:50%;
    position: relative;
  display: inline-block;
  box-shadow: 0 1px 2px rgba(0,0,0,0.15);
  transition: all 0.3s ease-in-out;

    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    &::after {
    position: absolute;
    z-index: -1;
    opacity: 0;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    transition: opacity 0.3s ease-in-out;
  }
    &:hover::after {
        opacity: 1;
    }
    &:focus, &:hover, &:active {

        background-color: #FA923B;
    }
    &--selected {
        background-color: #FA923B;
        pointer-events: none;
    }
    &--unselected {
        background-color: #FFC441;
    }
}

@media #{$medium-only} {
    .button, .btn {
        width:40%;
    }
}
@media #{$large-up} {
    .button, .btn {
        width:30%;
    }
}
