.circle {
    &:hover {
    }
    &--lrs {
        background-color: #FF9A34;
    }
    &--apl {
        background-color: #FFC441;
    }
}


//table gets its style from alvogen-arena.scss
