/**
    Stylesheet for the main attributes of an alvogen table.

    * Some attributes as font-sizes etc could mabye be set in settings
*/
.table {
    &__column:first-of-type {
        vertical-align: middle;
        display: inline-block;
        text-align: left;
    }
    &__column {
        vertical-align: middle;
        text-align: center;
        display: inline-block;
    }
    &__header {
        height: rem-calc(40px);
        background: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
    }
    &__body {
        padding-top: 2%;
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        &__position {
            margin-right: rem-calc(2px);
        }
        @media #{$large-up} {
            &__position {
                margin-right: rem-calc(10px);
            }

        }

    }
    &__text {

        display: inline-block;
        font-size: rem-calc(12px);
    }
    @media #{$medium-only}{
        &__text {
            font-size: rem-calc(14px);
        }
    }
    @media #{$large-up} {
        &__text {
            font-size: rem-calc(16px);
        }
    }
}
