.right-off-canvas-menu {
    background-color: #EEEEEE;
    top: rem-calc(-2px);
    right: rem-calc(2px);
    box-shadow: -2px 50px 5px 0px rgba(0, 0, 0, 0.16), 0px 0px 0px 0 rgba(0, 0, 0, 0.12);
}

.side-navv li a:not(.button):hover, .side-navv li a:not(.button):focus {
    background: #efefef !important;
    color: #1cc7ff;
}

.side-navv li a:not(.button):hover, .side-navv li a:not(.button):focus {
    background: #EEEEEE;

}
.side-navv li {
    a {
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    }
}

.side-navv {
    background-color: #EEEEEE;
    // height: 100%;
    &__link {
        border: none !important;
        -o-transition:2.5s;
        -ms-transition:2.5s;
        -moz-transition:2.5s;
        -webkit-transition:2.5s;
        /* ...and now for the proper property */
        transition:2.5s;
        p {
            font-size: 1rem;
            line-height: 2rem;
            color: #777777;
            margin: 8px 10px -2px;

        }
    }
    &__link:hover {
        p {

            color: #B2B2B2;
        }
    }

    &__divider {
        width: rem-calc(210px);
        height: rem-calc(1px);
        background-color: #E6E6E6;
        position: absolute;
        margin-left: rem-calc(20px);
    }

}


.top-bar {
    // height: 40px;
    margin-bottom: 3%;
    // display: flex;
    // vertical-align: middle;
    background-color: #333333;
}

.sticky {
    background-color: #333333;
}


    #nav-icon4 {
        padding-bottom:rem-calc(3px);
        position: absolute;
        vertical-align: middle;
            width: 1.875rem;
            height: 1.375rem;
            /* position: relative; */
            /* margin: 0 auto; */
            transform: rotate(0deg);
            transition: .5s ease-in-out;
            cursor: pointer;
            // margin-left: 3%;
            margin-top: 11px;
    }



#nav-icon4 span {
  display: block;
  position: absolute;
  height: rem-calc(3px);
  width: 100%;
  background: $primary;
  border-radius: rem-calc(9px);
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}


#nav-icon4 {
}

#nav-icon4 span:nth-child(1) {
  top: rem-calc(0px);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: rem-calc(8px);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
  top: rem-calc(16px);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
#nav-icon4.open span {
    background: #ffffff;
}
#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: rem-calc(-3px);
  left: rem-calc(8px);
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: rem-calc(18px);
  left: rem-calc(8px);
}
