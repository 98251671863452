.table__column--4columns{
    width:12%;
    &:first-of-type {
        width:58%;
    }
}

@media #{$medium-up} {
    .table__column--4columns {
        width:19%;
        &:first-of-type {
            width:40%;
        }
    }
}
