.apl__answers {
    padding-top:3%;
    //TABLE HEADER
    &__header {
        height: rem-calc(40px);
        background: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;

        &__column:first-of-type {
            vertical-align: middle;
            display: inline-block;
            width:28%;
            text-align: left;
        }



        &__column {

            vertical-align: middle;
            text-align: center;
            display: inline-block;
            width:32%;
        }

        @media #{$medium-up}{
            &__column:first-of-type {
                width:28%;
            }
            &__column {
                width:32%;
            }
        }
    }

    //TABLE BODY
    &__body {
        padding-top: 2%;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // vertical-align: middle;
        .apl__aa__table__body__column:first-of-type {
            vertical-align: middle;
            display: inline-block;
            width:58%;

        }



        .apl__aa__table__body__column {
            text-align: left;
            vertical-align: middle;
            // text-align: center;
            display: inline-block;
            // width:25%;
        }


        @media #{$medium-up}{
            .apl__aa__table__body__column:first-of-type {
                width:58%;
            }



            .apl__aa__table__body__column {
                width:19%;
            }

        }
        span, p {

            display: inline-block;
            font-size: rem-calc(12px);
        }
        @media #{$medium-only}{
            span, p {
                font-size: rem-calc(14px);
            }
        }
        @media #{$large-up} {
            span, p {
                font-size: rem-calc(16px);
            }
        }
    }
}
