$primary: #FDD964;

.montseratt {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    &--bold {
        font-weight: 700;
    }
}

body,html{
    height:100%;
    width:100%;
}

.off-canvas-wrap,.inner-wrap{
    height:100%;
}


.text--primary {
    color: $primary;
}
