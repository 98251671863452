@import "bower_components/foundation/scss/foundation/components/global";
@import "bower_components/foundation/scss/foundation/components/grid";
@import "bower_components/foundation/scss/foundation/components/type";
@import "bower_components/foundation/scss/foundation/components/forms";
@import "bower_components/foundation/scss/foundation/components/buttons";
@import "bower_components/foundation/scss/foundation/components/block-grid";
@import "bower_components/foundation/scss/foundation/components/inline-lists";
@import "bower_components/foundation/scss/foundation/components/visibility";
@import "bower_components/foundation/scss/foundation/components/offcanvas";
@import 'bower_components/foundation/scss/foundation/components/pagination';
@import 'bower_components/foundation/scss/foundation/components/side-nav';
@import 'bower_components/foundation/scss/foundation/components/top-bar';

@import "settings";
@import "global";
@import "navbar";
@import "footer";
@import "home";
@import "alvogen-arena";
@import "last-round-special";
@import "answers";
@import "hall-of-fame";
@import "rules-prizes";
@import "news";

@import "table/main";
@import "table/4columns";

.montseratt {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    &--bold {
        font-weight: 700;
    }
}

p, h1, span, a {
    font-family: 'Montserrat', sans-serif;
}
    //Alvogen.com
    //#FFCC00
    //#FECD2D

    //apl zone
    //alvogen arena
    // #FDD964

    // h3 {
    //     font-size:rem-calc(25px) !important;
    // }
    //
    // @media #{$medium-only} {
    //     h3 {
    //         font-size:rem-calc(30px) !important;
    //     }
    // }
    //
    // @media #{$large-up} {
    //     h3 {
    //         font-size:rem-calc(36px) !important;
    //     }
    // }

.circle {
	width: rem-calc(250px);
	height: rem-calc(250px);
    margin: 0 auto;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    margin-bottom: rem-calc(20px);
    margin-top: rem-calc(20px);
}

@media #{$large-up} {
    .circle {
        margin-left: 0px;

    }
}

.circle-text, .circle-text p {


        margin-bottom: rem-calc(10px);
        color: white;
        font-size: rem-calc(18px);

}


.freebirdFormviewerViewFormBanner, .freebirdHeaderMast {
    background:yellow !important;
}

.logo-link {
    background-image: url("https://apl20.files.wordpress.com/2015/01/header_apl_medpil-01-01.png");
    /* width: 100%; */
    margin-top: rem-calc(8px);
    height: 2.5rem;
    background-size: contain;
    text-indent: -9999px;
    display: block;
    z-index: 99999;
    background-repeat: no-repeat;
    background-position: 0 0;
    /* margin-top: 1.25rem; */
    /* margin-bottom: -.75rem; */
}
.logo-link {
    left:0;
    width: 15.75rem;
    /* margin-top: 1.125rem; */
    /* padding-top: 1.875rem; */
}
@media #{$small-only} {
    .logo-link {
        margin-left: rem-calc(-50px);
    }
}

.coupon__container {
    height: rem-calc(2880px);
}
@media #{$medium-up} {
    .coupon__container {
        height: rem-calc(2400px);
    }
}
@media #{$large-up} {
    .coupon__container {
        height: rem-calc(2250px);
    }
}

@media print {
  dialog, .reveal-modal, dialog {
    display: none;
    background: white !important; } }
.side-navv {
  display: block;
  margin: 0;
  padding: 0.875rem 0;
  list-style-type: none;
  list-style-position: outside;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }
  .side-navv li {
    margin: 0 0 0.4375rem 0;
    font-size: 0.875rem;
    font-weight: normal; }
    .side-navv li a:not(.button) {
      display: block;
      color: #008cba;
      margin: 0;
      padding: 0.4375rem 0.875rem; }
      .side-navv li a:not(.button):hover, .side-navv li a:not(.button):focus {
        background: rgba(0, 0, 0, 0.025);
        color: #1cc7ff; }
    .side-navv li.active > a:first-child:not(.button) {
      color: #1cc7ff;
      font-weight: normal;
      font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }
    .side-navv li.divider {
      border-top: 1px solid;
      height: 0;
      padding: 0;
      list-style: none;
      border-top-color: white; }
    .side-navv li.heading {
      color: #008cba;
      font-size: 0.875rem;
      font-weight: bold;
      text-transform: uppercase; }
